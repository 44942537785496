import actions from './actions'

const initialState = {
  id: null,
  order: 0,
  startAt: '',
  endAt: '',
  subject: '',
  hospital: '',
  streamingUrl: '',
  type: '',
  principalInverstigator: '',
  loading: false,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
