const PATH = {
  HOME: '/',
  WELCOME: '/welcome',
  STORED_WELCOME: '/home',
  LIVE: '/live',
  VOD: '/vod',
  ABOUT: '/about',
  ABOUT_GREETING: '/about/greetings',
  ABOUT_PLATFORM: '/about/platform',
  ABOUT_SCIENTIST: '/about/scientist',
  SURVEY: '/survey',
  SURVEY_COMPLETE: '/survey-complete',
  PROGRAMS: '/programs',
  SPEAKER: '/speaker',
  GUIDE: '/guide',
  GRADE_INFORMATION: '/grade-information',
  MYPAGE: '/mypage',
  SPONSOR: '/sponsor',
  HISTORY: '/history',
  LOCATION: '/location',
  TABLET: '/open/tablet',
  PRIVACY: '/privacy',
  TERMS: '/terms-of-use',
  PAGE: '/page',
  FAMILY_SITES: '/family-sites',
  BBS: {
    LIST: '/bbs/list',
    POST: '/bbs/post',
    READ: '/bbs/read',
  },

  AUTH: {
    LOGIN: '/auth/signin',
    LOGOUT: '/auth/signout',
    REGISTER: '/auth/signup',
    REGISTER_SHORT: '/r',
    REGISTER_INFO: '/auth/information',
    REGISTER_CONFIRM: '/auth/confirm-registration',
    FORGOT_PASSWORD: '/auth/forgot-password',
    REGISTER_COMPLETE: '/auth/welcome',
    REGISTER_FAILED: '/auth/register-error',
  },

  SYSTEM_404: '/auth/404',
}

export default PATH
