import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/database'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import 'firebase/compat/analytics'
import 'firebase/compat/storage'
import ReduxSagaFirebase from 'redux-saga-firebase'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
}

firebase.initializeApp(firebaseConfig)

export const firebaseAuth = firebase.auth()
export const firebaseDatabase = firebase.database()
export const fireStore = firebase.firestore()
export const fireStorage = firebase.storage()
export const fireBase = firebase
export const rsFirebase = new ReduxSagaFirebase(fireBase)
export const analytics = firebase.analytics()

export const db = getFirestore()
