/* eslint-disable no-unused-vars */
import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'

import { history } from 'App'
import * as firebase from 'services/firebase'
// import { USER } from 'constant'
import PATH from 'path.config'
// import { history } from 'App'
import _ from 'lodash'
import { rsFirebase, fireBase } from '../../firebase'
// import { USER, PATH } from 'constant'
import actions from './actions'

export function* ADD({ payload }) {
  const user = yield select((state) => state.user)
  const data = {
    user,
    ...payload,
    // order: 999999,
    createdAt: fireBase.firestore.Timestamp.now(),
  }
  // console.log('completed surveys', user.completedSurveys)
  // console.log('survy id ', data.surveyId)
  // console.log('findresult', _.findIndex(user.completedSurveys, data.surveyId))

  if (user.surveyAllowed === true && _.findIndex(user.completedSurveys, data.surveyId) < 0) {
    // console.log(user)

    // console.log(data)
    yield call(rsFirebase.firestore.addDocument, 'surveyResult', data)

    let completedSurveys = []
    if (user.completedSurveys) {
      completedSurveys = user.completedSurveys
    }
    completedSurveys.push(data.surveyId)

    const userSurveyCompleteData = {
      id: user.id,
      values: {
        completedSurveys: completedSurveys,
      },
    }
    yield history.push(PATH.SURVEY_COMPLETE)
    const success = yield call(firebase.updateUser, userSurveyCompleteData)

    notification.success({
      message: '설문조사 완료',
      description: '설문조사에 시간을 내어 참가해 주셔서 감사합니다.',
      duration: 2,
    })

    yield put({
      type: 'user/SET_STATE',
      payload: {
        completedSurveys: completedSurveys,
      },
    })
  } else {
    notification.error({
      message: '완료',
      description: '이미 설문조사를 완료하셨습니다.',
      duration: 2,
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.ADD, ADD)])
}
