const actions = {
  SET_STATE: 'session/SET_STATE',
  GET_DATA: 'session/GET_DATA',
  GET_LIST: 'session/GET_LIST',
  ACTIVATE: 'session/ACTIVATE',
  CURRENT: 'session/CURRENT',
  REGIST_QUESTION: 'session/REGIST_QUESTION',
  LIVE_STATE: 'session/LIVE_STATE',
}

export default actions
