import localeAntd from 'antd/es/locale/ko_KR'

const messages = {
  'topBar.profileMenu.signIn': 'Sign in',
  'topBar.profileMenu.signUp': 'Registration',
  'topBar.profileMenu.myPage': 'My Page',
  'topBar.profileMenu.logout': 'Sign out',
  'menu.mobile.home': 'Home',

  'auth.signIn': 'Sign in',
  'auth.signUp': 'Registration',
  'auth.register': 'Registration',

  'auth.complete.success': 'Registration complete',
  'auth.complete.failed': 'Registration failed',

  'auth.confirm.title': 'Confirm Registration',
  'auth.confirm.message': 'You can confirm with your Email and Name which used at Signing up.',
  'auth.confirm.placeholder.name': 'Name',
  'auth.confirm.placeholder.email': 'E-mail',
  'auth.confirm.failed': 'Registration not found.',
  'auth.confirm.button': 'Check',
  'auth.confirm.userInfo.status.green': 'Processing',
  'auth.confirm.userInfo.status.processing': 'Confirmed',
  'auth.confirm.userInfo.message': 'Registration confirmed.',
  'auth.confirm.userInfo.table.title': 'Information',
  'auth.confirm.userInfo.table.name': 'Name',
  'auth.confirm.userInfo.table.email': 'Email',
  'auth.confirm.userInfo.table.registeredAt': 'Registed at',
  'auth.confirm.userInfo.status': 'Status',
  'auth.confirm.userInfo.success': 'CONFIRMED',
  'auth.confirm.userInfo.pending': 'PENDING',

  'auth.forgotPassword.title': 'Forgotten your password?',
  'auth.forgotPassword.message':
    "Please enter your email address and we'll send you a link to reset your password",
  'auth.forgotPassword.form.emailRequired': 'e-mail address is required',
  'auth.forgotPassword.placeholder.email': 'e-mail',
  'auth.forgotPassword.sendButton': 'Continue',

  'auth.information.title': 'Registration Instructions',
  'auth.information.button': 'Registration',

  'auth.login.title': 'Sign in',
  'auth.login.loading': 'Loading login form',
  'auth.login.blocked': 'Sign in is not allowed.',
  'auth.login.placeholder.email': 'E-Mail',
  'auth.login.placeholder.password': 'Password',
  'auth.login.placeholder.passwordConfirm': 'Confirm Password',
  'auth.login.passwordForgotLink': 'Forget Password?',
  'auth.login.submit': 'Sign in',
  'auth.login.requiredEmail': 'e-mail is required',
  'auth.login.requiredPassword': 'password is required.',
  'auth.login.naver.processing': 'Now Authorizing with NAVER...',
  'auth.login.naver.signingin': 'Signing in',
  'auth.login.naver.processingSub': 'Please wait',

  'auth.login.code.processing': 'Now Authorizing...',
  'auth.login.code.signingin': 'Signing in',
  'auth.login.code.processingSub': 'Please wait',
  'auth.login.code.error': 'SMS Access code has been expired.',
  'auth.login.code.block': 'SMS / LINK sign in not allowed',
  'auth.signup.title': 'Registration',

  'auth.signup.login.title': 'Sign in information',
  'auth.signup.additional.title': 'Additional user information',

  'auth.signup.message.email': 'Please enter an',
  'auth.signup.message.email.1': 'e-mail',
  'auth.signup.message.email.2': ' address for ',
  'auth.signup.message.email.3': 'sign in ',
  'auth.signup.message.email.4': ' ',
  'auth.signup.placeholder.email': 'e-mail',
  'auth.signup.placeholder.password': 'Password',
  'auth.signup.placeholder.passwordConfirm': 'Confirm password',
  'auth.signup.passwordForgotLink': 'Forget password?',
  'auth.signup.requiredEmail': 'E-mail is required',
  'auth.signup.emailExists': 'E-Mail address already exists.',
  'auth.signup.isRequired': 'is required',
  'auth.signup.passwordHelp': 'Password must be at least 6 characters in length ',

  'auth.signup.requiredPassword': 'Password is required',
  'auth.signup.inputValidEmail': 'Email must be valid Email address format.',
  'auth.signup.inputValidPassword': 'Password must be at least 6 characters',
  'auth.signup.confirmPasswordNotMatch': 'Password and Password confirm do not match.',

  'auth.signup.additional.required': 'is required',
  'auth.signup.agree.prefix': 'By registration, I agree to',
  'auth.signup.agree.privacy': 'privacy policy',
  'auth.signup.agree.suffix': ' ',
  'auth.signup.agree.nda': 'NDA',
  'auth.signup.submit': 'Register',
  'auth.signup.loading': 'Loading Registration form',
  'auth.signup.blocked': 'Registration not allowed',
  'auth.authorize.required': 'Required Singing In',
  'auth.authorize.blocked': 'You are not allowed to access this page',
  'auth.authorize.finishSignUp': 'You need to submit more information to finish signing up',

  'auth.error.userNotFound.title': 'User not found',
  'auth.error.userNotFound.description': 'Input your email address',
  'auth.error.password.title': 'Wrong password',
  'auth.error.password.description':
    'Please click "Forgot Password" link, if you forgot your password',
  'auth.error.multiSignIn.title': 'Multiple Signing in is not allowed.',
  'auth.error.multiSignIn.description':
    'Logged out because your account logged in from another device.  ',

  'page.welcome.registerButton': 'Registration',
  'page.welcome.aboutButton': 'Greetings',
  'page.welcome.liveButton': 'Live',
  'page.welcome.programButton': 'Program',
  'page.welcome.loginButton': 'Sign in',

  'page.about.title': 'Greetings',
  'page.about.registerButton': 'Registration',
  'page.about.programButton': 'Program',
  'page.about.empty': '',
  'page.about.loading': 'Loading About Page',

  'page.live.countdown.day': 'Days',
  'page.live.countdown.hour': 'Hours',
  'page.live.countdown.minutes': 'Minutes',
  'page.live.countdown.seconds': 'Seconds',
  'page.live.countdown.remains': 'remains',
  'page.live.countdown.message': `Live event started Please wait a moment`,
  'page.live.title': 'Live',
  'page.live.timeFormat': 'HH:mm',
  'page.live.viewMode.cinema': 'Cinema View',
  'page.live.viewMode.standard': 'Default View',
  'page.live.loading': 'Preparing Live Streaming..',
  'page.live.blocked': "You're not allowed to access live streaming.",
  'page.live.empty': 'There is no live streaming schedule now.',
  'page.live.fin': 'Live streaming finished.',

  'page.vod.title': 'VOD',
  'page.vod.play': 'PLAY',

  'page.program.title': 'Program',

  'page.speaker.title': 'Speaker',

  'page.survey.title': 'Survey',
  'page.survey.submit': 'Confirm',
  'page.survey.complete': 'You already finished this survey',
  'page.survey.empty': 'There is no survey on this symposium',

  'page.survey.answer.yes': 'Yes',
  'page.survey.answer.no': 'No',
  'page.survey.answer.noRank': 'No rank',

  'page.survey.loading': 'Loading Survey...',
  'page.survey.blocked': 'You have no permission to survey',

  'page.privacy.title': 'Privacy Policy',
  'page.terms.title': 'Terms of use',

  'message.signinRequired': 'Signing in required',
  'message.signinRequiredPage': 'Signing in is required',

  'page.sponsor.title': 'Sponsor',
  'page.guide.title': 'Support',
  'page.gradeInformation.title': '평점 안내',

  'word.cancel': 'Cancel',

  'chat.askInputForm': 'Enter question',
  'chat.title': 'Live Q&A',
  'chat.send': 'Send',
  'chat.emptyMessage': 'There is no message yet',
  'chat.blocked': 'your account is not allowed to chat.',

  'firebase.emailExists': 'E-mail address aleady exists',
  'firebase.invalidEmail': 'Wrong email format',
  'firebase.weekPassword': 'password is weak',

  'page.title.welcome': 'Home',
  'page.title.support': 'Support',
  'page.title.seminar': 'Seminar',
  'page.title.signin': 'Sign in',
  'page.title.signup': 'Sign up',
  'page.title.forgotpassword': 'Forgot password',
  'page.title.survey': 'Survey',
  'page.title.vod': 'VOD',
}

export default {
  locale: 'en-US',
  localeAntd,
  messages,
}
