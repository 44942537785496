import React from 'react'
import { Skeleton } from 'antd'
import style from './style.module.scss'

const PageLoading = ({ message, rows }) => {
  return (
    <div className={`${style.container} ${style.skeleton}`}>
      {message ? <div className={style.message}>{message}</div> : null}
      <Skeleton active paragraph={{ rows: rows }} />
    </div>
  )
}

export default PageLoading
