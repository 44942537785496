// eslint-disable-next-line no-unused-vars
import React from 'react'
import { Input, Form, Select, Checkbox, Divider } from 'antd'
import { useIntl, FormattedMessage } from 'react-intl'
import _ from 'lodash'
import { USER_FIELD_TYPE } from 'constant'
import style from '../style.module.scss'

// const { Option } = Select

const UserProfileForm = ({ userProfileField, initialValues }) => {
  const intl = useIntl()

  const getFieldFormItem = (field, valueOption) => {
    // console.log(valueOption.value)
    if (field.type === USER_FIELD_TYPE.TEXT) {
      return (
        <Form.Item
          name={['profile', field.key]}
          rules={[
            {
              required: field.required,
              message: `${field.title} ${intl.formatMessage({
                id: 'auth.signup.additional.required',
              })}`,
            },
          ]}
          className={`mb-4 ${style.formItem}`}
          required={field.required}
          key={field.key}
          placeholder={field.placeholder}
          // readOnly={valueOption.disabled}
          initialValue={valueOption.value}
        >
          <Input
            size="large"
            placeholder={field.placeHolder}
            readOnly={valueOption.disabled}
            // border={(!valueOption.disabled).toString()}
            // value={valueOption.value || null}
          />
        </Form.Item>
      )
    }
    if (field.type === USER_FIELD_TYPE.SELECT) {
      return (
        <Form.Item
          name={['profile', field.key]}
          rules={[
            {
              required: field.required,
              message: `${field.title} ${intl.formatMessage({
                id: 'auth.signup.additional.required',
              })}`,
            },
          ]}
          className={`mb-4 ${style.formItem}`}
          required={field.required}
          key={field.key}
          placeholder={field.placeholder}
        >
          <Select size="large" placeholder={field.placeHolder} labelInValue>
            {field.options.map((option) => {
              return (
                <Select.Option value={option.key} key={option.key}>
                  {option.value}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>
      )
    }
    if (field.type === USER_FIELD_TYPE.CHECK) {
      // return <Checkbox>{field.title}</Checkbox>
      // console.log(field)

      return (
        <>
          <p className={style.fieldLabelText}>{field.title}</p>
          {field.options.map((option) => {
            const checkKey = `${field.key}_${option.key}`
            return (
              <Form.Item
                name={['profile', field.key, option.key]}
                className={`mb-4 ${style.checkboxItem}`}
                key={checkKey}
                valuePropName="checked"
              >
                <Checkbox>{option.value}</Checkbox>
              </Form.Item>
            )
          })}
        </>
      )
    }
    if (field.type === USER_FIELD_TYPE.NUMBER) {
      return (
        <Form.Item
          name={['profile', field.key]}
          rules={[
            {
              required: field.required,
              message: `${field.title} ${intl.formatMessage({
                id: 'auth.signup.additional.required',
              })}`,
            },
          ]}
          className={`mb-4 ${style.formItem}`}
          required={field.required}
          key={field.key}
          placeholder={field.placeholder}
        >
          <Input size="large" placeholder={field.placeHolder} />
        </Form.Item>
      )
    }
    if (field.type === USER_FIELD_TYPE.PHONE) {
      return (
        <Form.Item
          name={['profile', field.key]}
          rules={[
            {
              required: field.required,
              message: `${field.title} ${intl.formatMessage({
                id: 'auth.signup.additional.required',
              })}`,
            },
          ]}
          className={`mb-4 ${style.formItem}`}
          required={field.required}
          key={field.key}
          placeholder={field.placeholder}
        >
          <Input size="large" placeholder={field.placeHolder} />
        </Form.Item>
      )
    }
    if (field.type === USER_FIELD_TYPE.BOOL) {
      return (
        <Form.Item
          name={['profile', field.key]}
          rules={[
            {
              required: field.required,
              message: `${field.title} ${intl.formatMessage({
                id: 'auth.signup.additional.required',
              })}`,
            },
          ]}
          className={`mb-4 ${style.formItem}`}
          required={field.required}
          key={field.key}
          placeholder={field.placeholder}
          valuePropName="checked"
        >
          <Checkbox> {field.title}</Checkbox>
        </Form.Item>
      )
    }

    return null
  }
  // console.log('userProfileValues -> initialValues', initialValues)

  return (
    <>
      <h4 className="mt-4">
        <FormattedMessage id="auth.signup.additional.title" />
      </h4>
      <Divider />
      {userProfileField &&
        userProfileField.fields &&
        userProfileField.fields.map((field) => {
          let options = {
            disabled: false,
            value: null,
          }
          if (initialValues && _.has(initialValues, field.key)) {
            options.disabled = true
            options.value = initialValues[field.key]
          }
          return <div key={field.key}>{getFieldFormItem(field, options)}</div>
        })}
    </>
  )
}

export default UserProfileForm
