const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  REGISTER: 'user/REGISTER',
  USER_EDIT: 'user/USER_EDIT',
  USER_DELETE: 'user/USER_DELETE',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'user/LOGOUT',
  FORGOT_PASSWORD: 'user/FORGOT_PASSWORD',
  SET_PAGE: 'user/SET_PAGE',
  SET_NAVER_ACCESS_TOKEN: 'user/SET_NAVER_ACCESS_TOKEN',
  LOGIN_NAVER: 'user/LOGIN_NAVER',
  LOGIN_CODE: 'user/LOGIN_CODE',
  REGISTER_NAVER_PROFILE: 'user/REGISTER_NAVER_PROFILE',
  GET_NAVER_PROFILE: 'user/GET_NAVER_PROFILE',
}

export default actions
