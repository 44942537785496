/* eslint-disable no-unused-vars */
// import moment from 'moment'
import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import * as firebase from 'services/firebase'
import { doc, updateDoc, arrayUnion } from 'firebase/firestore'
import { rsFirebase, fireBase, db } from '../../firebase'
import actions from './actions'

export function* ADD_VIEW_START_LOG({ payload }) {
  console.log('ADD_VIEW_START_LOG')
  // const now = fireBase.firestore.Timestamp.now().seconds
  const { user, vod, startTime } = payload
  const data = {
    userId: user.id,
    vodId: vod.id,
    createdAt: startTime,
    loggedAt: fireBase.firestore.Timestamp.now(),
  }
  // const vodLogRef = `/vodLogs/${vod.id}`
  // yield call(rsFirebase.firestore.setDocument, vodLogRef, schedule)

  const vodRef = `/vodLogs/${vod.id}`
  yield call(rsFirebase.firestore.setDocument, vodRef, vod)

  const userRef = `/vodLogs/${vod.id}/users/${user.id}`
  yield call(rsFirebase.firestore.setDocument, userRef, user)

  const logRef = `/vodLogs/${vod.id}/users/${user.id}/start/${startTime}`
  yield call(rsFirebase.firestore.setDocument, logRef, data)

  // yield call(rsFirebase.firestore.setDocument, documentRef, data)
}

export function* ADD_VIEW_UPDATE_LOG({ payload }) {
  console.log('ADD_VIEW_UPDATE_LOG')
  // const now = fireBase.firestore.Timestamp.now().seconds
  const { user, vod, startTime, currentTime } = payload
  const data = {
    currentTime: currentTime,
  }
  // const vodLogRef = `/vodLogs/${vod.id}`
  // yield call(rsFirebase.firestore.setDocument, vodLogRef, schedule)

  const vodRef = `/vodLogs/${vod.id}/users/${user.id}/start/${startTime}`
  const ref = doc(db, vodRef)
  // console.log('userRef', userRef)
  // yield call(rsFirebase.firestore.setDocument, userRef, data)
  updateDoc(ref, {
    pings: arrayUnion(currentTime),
  })

  yield put({
    type: 'vod/SET_STATE',
    payload: {
      loading: false,
    },
  })
  // yield call(rsFirebase.firestore.setDocument, documentRef, data)
}

export default function* rootSaga() {
  yield all([
    // takeEvery(actions.ADD, ADD),
    // takeEvery(actions.DELETE, DELETE),
    // takeEvery(actions.UPDATE, UPDATE),
    // takeEvery(actions.GET_LIST, GET_LIST),
    // takeEvery(actions.GET_DATA, GET_DATA),
    // takeEvery(actions.ENABLE_QNA, ENABLE_QNA),
    // takeEvery(actions.ACTIVATE, ACTIVATE),
    // takeEvery(actions.CURRENT, CURRENT),
    takeEvery(actions.ADD_VIEW_START_LOG, ADD_VIEW_START_LOG),
    takeEvery(actions.ADD_VIEW_UPDATE_LOG, ADD_VIEW_UPDATE_LOG),
  ])
}
