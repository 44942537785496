import { all, takeEvery, put, call } from 'redux-saga/effects'
import { currentSession } from 'services/firebase'
// import { fireBase } from '../../firebase'
import actions from './actions'

export function* CURRENT() {
  const session = yield call(currentSession)
  yield put({
    type: 'session/SET_STATE',
    payload: session,
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.CURRENT, CURRENT)])
}
