/* eslint-disable no-unused-vars */
import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'

import { history } from 'App'
import * as firebase from 'services/firebase'
// import { USER } from 'constant'
import PATH from 'path.config'
// import { history } from 'App'
import _ from 'lodash'
import { rsFirebase, fireBase } from '../../firebase'
// import { USER, PATH } from 'constant'
import actions from './actions'

export function* ADD({ payload }) {
  console.log('bbs saga add', payload)
  const user = yield select((state) => state.user)
  const data = {
    user,
    ...payload,
    createdAt: fireBase.firestore.Timestamp.now(),
  }

  const collection = `bbsData/${payload.bbs}/articles`

  // console.log(collection)
  yield call(rsFirebase.firestore.addDocument, collection, data)

  const bbsRef = `bbs/${payload.bbs}`
  const bbsData = {
    total: payload.order,
  }
  yield call(rsFirebase.firestore.setDocument, bbsRef, bbsData, { merge: true })

  notification.success({
    message: '게시물 등록',
    description: '게시물 등록 완료.',
    duration: 2,
  })
}

export function* UPDATE({ payload }) {
  console.log('bbs update', payload)
  const data = {
    ...payload,
  }

  const articleRef = `bbsData/${payload.bbs}/articles/${payload.id}`

  yield call(rsFirebase.firestore.setDocument, articleRef, data, { merge: true })

  notification.success({
    message: '게시물 수정',
    description: '게시물 수정 완료.',
    duration: 2,
  })
}

export function* DELETE({ payload }) {
  const articleRef = `bbsData/${payload.bbs}/articles/${payload.id}`
  yield call(rsFirebase.firestore.deleteDocument, articleRef)

  notification.success({
    message: '삭제 완료.',
    description: '게시물이 삭제되었습니다.',
    duration: 1,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.ADD, ADD),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.UPDATE, UPDATE),
  ])
}
