/* eslint-disable no-unused-vars */
import { all, takeEvery, put, call } from 'redux-saga/effects'
import * as firebase from 'services/firebase'
// import moment from 'moment'
import _ from 'lodash'
import { rsFirebase, fireBase, fireStore } from '../../firebase'
import actions from './actions'

export function* SEND_MESSAGE({ payload }) {
  const now = fireBase.firestore.Timestamp.now()
  const messageId = `${now.seconds}-${now.nanoseconds}`
  const data = {
    id: messageId,
    sessionId: payload.session.id,
    scheduleId: payload.session.scheduleId,
    message: payload.message,
    user: payload.user,
    deleted: false,
    highlight: false,
    createdTimestamp: now,
    createdAt: now.seconds,
  }
  const documentRef = `chat/${payload.session.id}/messages/${messageId}/`
  yield call(rsFirebase.firestore.setDocument, documentRef, data, { merge: true })
}

export function* TOGGLE_HIGHLIGHT({ payload }) {
  const highlighted = !payload.highlight
  const data = {
    highlight: highlighted,
  }
  const documentRef = `chat/${payload.session.id}/messages/${payload.id}/`
  yield call(rsFirebase.firestore.setDocument, documentRef, data, { merge: true })
}

export function* GET_MESSAGES({ payload }) {
  const { session, schedule, user, startDocId } = payload
  const isPublicChat = schedule.isPublicChat || false

  if (
    !session.id ||
    session.qnaEnabled === false ||
    session.qnaExists === false ||
    !schedule ||
    !user
  ) {
    return false
  }
  const collectionPath = `chat/${session.id}/messages`
  // const collectionRef = fireStore.collection(collectionPath)
  let collectionRef = fireStore.collection(collectionPath).orderBy('createdAt', 'desc').limit(50)
  if (startDocId) {
    collectionRef = collectionRef.startAt('id', startDocId)
  }
  // if (!isPublicChat) {
  //   collectionRef = fireStore.collection(collectionPath).where('user.id', '==', user.id)
  // } else {
  //   collectionRef = fireStore.collection(collectionPath)
  // }

  const successActionCreator = (data) => {
    let messageList = []
    // data.docChanges().forEach(change => {
    //   console.log(change)
    // })
    data.docs.forEach((message) => {
      if (isPublicChat) {
        messageList.push(message.data())
      } else if (
        message.data().user.id === user.id ||
        message.data().user.role === 'admin' ||
        user.role === 'admin'
      ) {
        messageList.push(message.data())
      }
    })

    messageList = _.sortBy(messageList, 'createdAt', 'asc')
    // console.log('successActionCreator')
    return {
      type: actions.SET_STATE,
      payload: { messages: messageList },
    }
  }

  yield call(rsFirebase.firestore.syncCollection, collectionRef, {
    successActionCreator,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CHAT_SEND_MESSAGE, SEND_MESSAGE),
    takeEvery(actions.TOGGLE_HIGHLIGHT, TOGGLE_HIGHLIGHT),
    takeEvery(actions.CHAT_GET_MESSAGES, GET_MESSAGES),
  ])
}
