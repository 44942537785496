import React from 'react'
import style from './style.module.scss'

const AppLoading = () => {
  return (
    <div className={style.splashScreen}>
      {/* <img src={toAbsoluteUrl('/assets/logo/fewclicks-beta.svg')} alt="LOGO" /> */}
      <div className={style.splashLoading}>
        <img src="/resources/images/loading-circle.svg" alt="loading" />
      </div>
    </div>
  )
}

export default AppLoading
