// import _ from 'lodash'
import { fireStore } from '../../firebase'
// import PATH from 'path.config'

export default async function getMenuData() {
  const ref = fireStore.collection('menus').where('opened', '==', true).orderBy('order', 'asc')
  let menus = []
  await ref.get().then((snapshot) => {
    snapshot.forEach((doc) => {
      if (doc.exists) {
        const data = doc.data()
        data.id = doc.id
        menus.push(data)
        return true
      }
      return false
    })
  })

  return menus
}
