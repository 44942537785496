/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import React from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import PageLoading from 'components/PageLoading'
import _ from 'lodash'
// import BigMessage from 'components/BigMessage'
import { renderDraft } from 'components/Helper'
import queryString from 'query-string'
import { analytics } from '../../firebase'
import style from './style.module.scss'

const PlainPage = () => {
  const intl = useIntl()
  const location = useLocation()
  const { id } = useParams()

  analytics.logEvent('page_view', {
    page_path: location.pathname,
    page_title: `Plain Page ${id}`,
  })

  const collection = 'pages/plain/pages'
  useFirestoreConnect({
    collection: collection,
    doc: id,
  })

  const page = useSelector(({ firestore: { data } }) => {
    if (data && _.has(data, collection) && _.has(data[collection], id)) {
      return data[collection][id]
    }
    return null
  })

  // if (!page) {
  //   return <BigMessage message={intl.formatMessage({ id: 'page.about.empty' })} />
  // }

  if (!page) {
    return <PageLoading message={intl.formatMessage({ id: 'page.about.loading' })} rows={15} />
  }

  return (
    <div className="pageContainer">
      <Helmet title={intl.formatMessage({ id: 'page.about.title' })} />
      <div className="introduceWrapper">
        <h1 className="introduceMainTitle">{page.title}</h1>
        {/* <div className="introduceTitle">
          <p>인사말</p>
        </div> */}
      </div>

      <div>
        <div className={`${style.introduceComment}`}>
          <div className="row">
            <div className="col-12 col-lg-12">
              {renderDraft(page.contents)}
              {page.speakerEnabled ? renderDraft(page.speaker) : null}
            </div>
          </div>
        </div>
      </div>
      {/* <div className={`${style.bottomLinkWrapper}`}>
        <Link to={PATH.PROGRAMS} className="mb-3 mr-0 mr-md-3 d-md-inline d-block">
          <button type="button" className={`${style.bottomButton} btn btn-secondary`}>
            <span>
              <FormattedMessage id="page.about.programButton" />
            </span>
          </button>
        </Link>
        <Link to={PATH.AUTH.REGISTER} className="mb-3 mr-0 mr-md-3 d-md-inline d-block">
          <button type="button" className={`${style.bottomButton} btn btn-secondary`}>
            <span>
              <FormattedMessage id="page.about.registerButton" />
            </span>
          </button>
        </Link>
      </div> */}
    </div>
  )
}

export default PlainPage
