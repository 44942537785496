import actions from './actions'

const initialState = {
  id: null,
  role: null,
  email: null,
  profile: {},
  allowed: false,
  surveyAllowed: false,
  chatAllowed: false,
  completedSurveys: [],
  authorized: process.env.REACT_APP_AUTHENTICATED || false, // false is default value
  loading: false,
  blocked: false,
  loginToken: null,
  naverAccessToken: null,
  naverProfile: null,
  authProvider: null,
  signUpFinished: false,
  error: null,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
