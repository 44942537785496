/* eslint-disable no-unused-vars */
import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import * as firebase from 'services/firebase'
import { rsFirebase, fireBase } from '../../firebase'
import actions from './actions'

// eslint-disable-next-line require-yield
export function* ADD_LIVE_LOG({ payload }) {
  const now = fireBase.firestore.Timestamp.now().seconds
  const { user, schedule } = payload
  const data = {
    user,
    schedule,
    createdAt: now,
    loggedAt: fireBase.firestore.Timestamp.now(),
  }
  const scheduleRef = `/logs/${schedule.id}`
  yield call(rsFirebase.firestore.setDocument, scheduleRef, schedule)

  const userRef = `/logs/${schedule.id}/liveLogs/${user.id}`
  yield call(rsFirebase.firestore.setDocument, userRef, user)

  const documentRef = `/logs/${schedule.id}/liveLogs/${user.id}/userLiveLogs/${now}`
  console.log(documentRef)
  console.log(data)
  yield call(rsFirebase.firestore.setDocument, documentRef, data)
}

export default function* rootSaga() {
  yield all([takeEvery(actions.ADD_LIVE_LOG, ADD_LIVE_LOG)])
}
