/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Form, notification, Button } from 'antd'
import _ from 'lodash'
import { useLocation, Navigate } from 'react-router-dom'
import { useIntl, FormattedMessage } from 'react-intl'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
// import { useFirestoreConnect } from 'react-redux-firebase'
import BigMessage from 'components/BigMessage'
import queryString from 'query-string'
import PATH from 'path.config'
import UserProfileForm from '../Signup/UserProfileForm'
import style from './style.module.scss'

const NaverAuth = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const intl = useIntl()
  // const [userProfileValues, setUserProfileValues] = useState()
  const query = queryString.parse(location.search)
  const accessCode = query.code
  const user = useSelector((state) => state.user, shallowEqual)
  const userProfileField = useSelector((state) => state.userProfileField)

  const signIn = async (code) => {
    dispatch({
      type: 'user/LOGIN_NAVER',
      payload: { code: code },
    })
  }

  const onFinish = (values) => {
    // if (NDASigned === true) {
    // console.log(values)
    const payload = {
      user: user,
      profile: values,
    }
    console.log('payload', payload)

    dispatch({
      type: 'user/REGISTER_NAVER_PROFILE',
      payload: payload,
    })
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
    let errorMessage = intl.formatMessage({ id: 'auth.signup.isRequired' })

    notification.warning({
      // message: error.code,
      message: 'Sign up error',
      description: errorMessage,
      duration: 2,
    })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // console.log(accessCode)
    // console.log(user.authorized)
    // console.log(user.loading)
    if (accessCode && !user.authorized && user.loading === false) {
      signIn(accessCode)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.loading])

  useEffect(() => {
    if (user && user.email && user.signUpFinished === false && !user.naverProfile) {
      console.log('dispatch naver profile')
      dispatch({ type: 'user/GET_NAVER_PROFILE' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.email, user.signUpFinished])

  // useEffect(() => {
  //   if (user.naverProfile) {
  //     const profile = {}
  //     profile.name = user.naverProfile.name || null
  //     profile.phonenumber = user.naverProfile.mobile_e164 || null
  //     setUserProfileValues(profile)
  //   }
  // }, [user.naverProfile])
  // console.log('userProfileValues', userProfileValues)

  console.log('auth/naver', user)
  if (!user || !user.id || user.id.length < 1 || !user.naverAccessToken) {
    return (
      <BigMessage
        message={intl.formatMessage({ id: 'auth.login.naver.processing' })}
        description={intl.formatMessage({ id: 'auth.login.naver.processingSub' })}
        loading
      />
    )
  }

  const userProfileValues = {
    name: user.naverProfile.name || null,
    phonenumber: user.naverProfile.mobile || null,
  }

  if (user && user.email && user.signUpFinished === true && user.naverAuthType === 'REGISTER') {
    return <Navigate to={PATH.AUTH.REGISTER_COMPLETE} />
  }
  if (user && user.email && user.signUpFinished === true && user.naverAuthType === 'SIGNIN') {
    return <Navigate to={PATH.HOME} />
  }
  if (user && !user.email && user.signUpFinished === false) {
    return (
      <BigMessage
        message={intl.formatMessage({ id: 'auth.login.naver.signingin' })}
        description={intl.formatMessage({ id: 'auth.login.naver.processingSub' })}
        loading
      />
    )
  }

  if (!userProfileField || !_.has(userProfileField, 'fields')) {
    return <Navigate to={PATH.HOME} />
  }
  if (userProfileField && _.has(userProfileField, 'fields') && !userProfileValues) {
    return (
      <BigMessage
        message={intl.formatMessage({ id: 'auth.login.naver.processing' })}
        description={intl.formatMessage({ id: 'auth.login.naver.processingSub' })}
        loading
      />
    )
  }

  // console.log(userProfileValues)
  return (
    <div className={`${style.container}`}>
      <div className={style.containerInner}>
        <Helmet title={intl.formatMessage({ id: 'auth.signup.title' })} />
        <div className="introduceWrapper">
          <h1 className="introduceMainTitle">
            <FormattedMessage id="auth.signup.title" />
          </h1>
        </div>
        <div className={`card ${style.cardContainer} card-borderless`}>
          <Form
            layout="vertical"
            hideRequiredMark
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className={`mb-4 ${style.registerForm}`}
            autoComplete="off"
            // initialValues={userProfileValues}
          >
            <UserProfileForm
              userProfileField={userProfileField}
              initialValues={userProfileValues}
            />

            <div className="text-center">
              <Button
                htmlType="submit"
                className={`text-center width-200 ${style.submitButton}`}
                loading={user.loading}
              >
                <span className="font-size-21">
                  <FormattedMessage id="auth.signup.submit" />
                </span>
              </Button>
              {/* <NaverLogin title="Sign in with Naver Account" /> */}
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default NaverAuth
