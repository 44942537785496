/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { Form, notification, Button } from 'antd'
import _ from 'lodash'
import { useLocation, Navigate, useParams, Link } from 'react-router-dom'
import { useIntl, FormattedMessage } from 'react-intl'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
// import { useFirestoreConnect } from 'react-redux-firebase'
import BigMessage from 'components/BigMessage'
import queryString from 'query-string'
import PATH from 'path.config'
import style from './style.module.scss'

const TokenAuth = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const intl = useIntl()
  const settings = useSelector((state) => state.Project.settings)
  // const [userProfileValues, setUserProfileValues] = useState()
  const { code } = useParams()
  // console.log(code)

  const query = queryString.parse(location.search)
  let redirect = null
  if (query && query.link !== undefined) {
    // eslint-disable-next-line prefer-destructuring
    redirect = query.link
    // console.log('redirect to', redirect)
  }

  const user = useSelector((state) => state.user, shallowEqual)

  const signIn = async (aCode) => {
    // console.log('sign in with token')
    dispatch({
      type: 'user/LOGIN_CODE',
      payload: { code: aCode },
    })
  }

  const debounce = (func, timeout) => {
    let timer
    return (...args) => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        func.apply(this, args)
      }, timeout)
    }
  }

  const signInBounce = debounce(signIn, 500)

  useEffect(() => {
    if (code && settings && settings.signInWithTokenOpened === true) {
      signInBounce(code)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, settings])

  if (!settings) {
    return null
  }
  // console.log('auth/token', user)
  if (!settings.signInWithTokenOpened) {
    return (
      <div>
        <BigMessage
          message={intl.formatMessage({ id: 'auth.login.code.block' })}
          // description={intl.formatMessage({ id: 'auth.login.code.error' })}
          // loading
        />
        <div className={style.buttonWrapper}>
          <Link to={PATH.AUTH.LOGIN} className={`btn ${style.loginButton}`}>
            <FormattedMessage id="page.title.signin" />
          </Link>
        </div>
      </div>
    )
  }

  if (user && user.error) {
    return (
      <div>
        <BigMessage
          message={intl.formatMessage({ id: 'auth.login.code.error' })}
          // description={intl.formatMessage({ id: 'auth.login.code.error' })}
          // loading
        />
        <div className={style.buttonWrapper}>
          <Link to={PATH.AUTH.LOGIN} className={`btn ${style.loginButton}`}>
            <FormattedMessage id="page.title.signin" />
          </Link>
        </div>
      </div>
    )
  }

  if (!user || !user.id || user.id.length < 1) {
    return (
      <BigMessage
        message={intl.formatMessage({ id: 'auth.login.code.processing' })}
        description={intl.formatMessage({ id: 'auth.login.code.processingSub' })}
        loading
      />
    )
  }

  if (user && user.email && user.signUpFinished === true && user.loading === false) {
    return <Navigate to={redirect || PATH.HOME} />
  }
  // if (user && !user.email && user.signUpFinished === false) {
  //   return (
  //     <BigMessage
  //       message={intl.formatMessage({ id: 'auth.login.naver.signingin' })}
  //       description={intl.formatMessage({ id: 'auth.login.naver.processingSub' })}
  //       loading
  //     />
  //   )
  // }

  // console.log(userProfileValues)
  return null
}

export default TokenAuth
