import React from 'react'
import style from './style.module.scss'

const BigMessage = ({ message, description = '', loading = false }) => {
  return (
    <div className={`${style.container}`}>
      <div className={style.message}>{message}</div>
      <div className={style.description}>{description}</div>
      {loading === true ? <div className={style.loading}> </div> : null}
    </div>
  )
}

export default BigMessage
