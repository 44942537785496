import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import session from './session/sagas'
import vod from './vod/sagas'
import survey from './survey/sagas'
import userProfileField from './userProfileField/sagas'
import chat from './chat/sagas'
import logger from './Logger/sagas'
import Project from './Project/sagas'
import BBS from './bbs/sagas'

export default function* rootSaga() {
  yield all([
    user(),
    menu(),
    settings(),
    session(),
    vod(),
    survey(),
    userProfileField(),
    chat(),
    logger(),
    Project(),
    BBS(),
  ])
  // yield all([user(), settings()])
}
