/* eslint-disable no-unused-vars */
import React from 'react'
import { Layout } from 'antd'
import classNames from 'classnames'
import TopBar from 'components/UI/layout/MobileTopBar'
import Menu from 'components/UI/layout/Menu'
import Footer from 'components/UI/layout/Footer'
import style from './style.module.scss'

const MainLayout = ({ children, transparentNavigation }) => {
  return (
    <Layout
      className={classNames(
        {
          cui__layout__contentMaxWidth: false,
          cui__layout__appMaxWidth: false,
        },
        style.page,
      )}
    >
      <Menu transparent={transparentNavigation} />
      <Layout className={`${style.main}`}>
        <Layout.Header
          className={classNames(
            'cui__layout__header',
            {
              cui__layout__fixedHeader: false,
            },
            style.mobileTopBar,
          )}
        >
          <TopBar />
        </Layout.Header>
        <Layout.Content style={{ height: '100%', position: 'relative' }}>
          <div className={`cui__utils__content ${style.mainContent}`}>{children}</div>
        </Layout.Content>
        <Layout.Footer>
          <Footer />
        </Layout.Footer>
      </Layout>
    </Layout>
  )
}

export default MainLayout
