/* eslint-disable no-unused-vars */
import React from 'react'
import { useLocation } from 'react-router-dom'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
import { TEXT } from 'constant'
import MainLayout from './Main'

NProgress.configure({ showSpinner: false })

const Layout = ({ user, children }) => {
  // const params = useParams()
  const location = useLocation()
  // const navigate = useNavigate()

  // NProgress & ScrollTop Management
  // const [transparentNavigation, setTransparentNavigation] = useState(false)

  const { pathname, search } = location
  const currentPath = pathname + search
  let previousPath = ''
  if (currentPath !== previousPath) {
    window.scrollTo(0, 0)
    NProgress.start()
  }
  setTimeout(() => {
    NProgress.done()
    previousPath = currentPath
  }, 300)

  return (
    <>
      <Helmet titleTemplate={`${TEXT.TITLE.TITLE} | %s`} title="" />
      <MainLayout>{children}</MainLayout>
    </>
  )
}

export default Layout
