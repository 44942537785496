// eslint-disable-next-line no-unused-vars
import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import * as firebase from 'services/firebase'
import actions from './actions'

export function* GET() {
  yield put({
    type: 'userProfileField/SET_STATE',
    payload: {
      loading: true,
    },
  })

  try {
    const response = yield call(firebase.getUserProfileField)
    yield put({
      type: 'userProfileField/SET_STATE',
      payload: {
        loading: false,
        fields: response,
      },
    })
  } catch (_e) {
    yield put({
      type: 'userProfileField/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET, GET), GET()])
}
